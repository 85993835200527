// import React from "react";

// const FeeTable = (props) => {
//   const fees = props.fees;
//   return (
//     <div className="container mx-auto p-10">
//       <p className="text-2xl font-bold my-4 text-center">{props.name}</p>
//       <table className="border border-black w-full">
//         <tr>
//           <th className="text-lg border border-black">{props.name === "Stationery" || props.name === "ECA" ? "Class":"Particulars"}</th>
//           <th className="text-lg border border-black">Fees</th>
//         </tr>
//         {fees.map((fee, index) => {
//           return (
//             <tr key={index}>
//               <td className="border border-black px-4 py-2">
//                 {fee.particulars}
//               </td>
//               <td className="border border-black px-4 py-2">{fee.fees}</td>
//             </tr>
//           );
//         })}
//       </table>
//     </div>
//   );
// };

// export default FeeTable;


import React from "react";

const FeeTable = ({ fees, customClass }) => {
  return (
    <div className="w-full">
      <table className="w-full text-left">
        <tbody>
          {fees.map((fee, index) => {
            return (
              <tr 
                key={index} 
                className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-green-50 transition-colors duration-150`}
              >
                <td className="px-4 py-3 border-b border-gray-200">
                  {fee.class || fee.particulars}
                </td>
                <td className="px-4 py-3 border-b border-gray-200 text-right font-medium text-green-900">
                  RS. {fee.fees}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FeeTable;