import React from "react";
import specialitiesData from "../SpecialityData";
import SpecialityCard from "../SpecialityCard";

const OurSpecialities = () => {
  return (
    <div className="my-20">
      <p className="text-5xl text-center text-green-900 font-bold my-10">
        Our Specialities
      </p>
      
      <div className="container mx-auto px-8">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {specialitiesData.map((speciality, index) => (
            <div 
              key={speciality.id}
              className="transform transition-all duration-300 hover:-translate-y-2"
              style={{ 
                opacity: 0,
                animation: 'fadeInUp 0.6s ease forwards',
                animationDelay: `${index * 0.1}s`
              }}
            >
              <SpecialityCard
                title={speciality.title || ""}
                image={speciality.image || ""}
                icon={speciality.icon || null}
                description={speciality.description || []}
              />
            </div>
          ))}
        </div>
      </div>
      
      {/* Add a global style for the animation */}
      <style jsx global>{`
        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default OurSpecialities;
