import React from "react";

const OurGoalsComp = () => {
  // Goals data array for easier management
  const goals = [
    "Develop every student's potential.",
    "Nurture a life-long love of learning to participate and showcase.",
    "Create an exciting environment.",
    "Encourage independent thinking, prepare every child to make a difference.",
    "Provide opportunities to reflect on life and learning.",
    "Ensure everyone has a voice and an opportunity to be heard.",
    "Encourage good health and well-being for all.",
    "Develop culture and a sense of respect towards every culture.",
    "Provide opportunities that challenge.",
    "Respect thoughts of every person and trace out the best.",
    "Develop a feeling of brotherhood and a nature lover.",
    "A sense to respect self and other's property.",
    "Respect local values, tolerance of other races, religions and the ways of life."
  ];

  return (
    <div className="py-24 bg-gradient-to-b from-orange-50 to-white">
      <div className="container mx-auto px-6 md:px-12">
        {/* Header Section */}
        <div className="text-center mb-16">
          <h2 className="font-heading font-bold text-4xl md:text-5xl text-green-950 mb-6 relative inline-block">
            OUR GOALS
            <div className="absolute top-14 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-orange-400 rounded-full"></div>
          </h2>
          <p className="mt-8 text-lg text-gray-700 max-w-3xl mx-auto">
            At Panini School, we are committed to fostering an environment where students can thrive academically, 
            socially, and emotionally. Our goals reflect our dedication to holistic education.
          </p>
        </div>

        {/* Goals Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
          {goals.map((goal, index) => (
            <div 
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden transform transition-all duration-300 hover:shadow-lg hover:-translate-y-1 flex flex-col"
              style={{ 
                opacity: 0,
                animation: 'fadeInUp 0.6s ease forwards',
                animationDelay: `${index * 0.1}s`
              }}
            >
              <div className="p-6 flex items-start flex-grow">
                <div className="flex-shrink-0 mr-4">
                  <div className="flex items-center justify-center w-10 h-10 rounded-full bg-green-100 text-green-600 font-bold">
                    {index + 1}
                  </div>
                </div>
                <div>
                  <p className="text-gray-700 leading-relaxed">{goal}</p>
                </div>
              </div>
              <div className={`h-1 w-full ${index % 2 === 0 ? "bg-gradient-to-r from-green-400 to-green-600" : "bg-gradient-to-r from-orange-400 to-orange-600"}`}></div>
            </div>
          ))}
        </div>

        {/* Visual Elements */}
        <div className="mt-20 text-center">
          <div className="inline-flex items-center justify-center p-6 rounded-full bg-orange-100 shadow-inner">
            <svg className="w-16 h-16 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path>
            </svg>
          </div>
          <p className="mt-6 text-xl font-medium text-green-800">
            Together, we build a foundation for lifelong success.
          </p>
        </div>
      </div>

      {/* Animation styles */}
      <style jsx global>{`
        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default OurGoalsComp;
