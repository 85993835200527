import React, { useState } from "react";

const SpecialityCard = ({ title, image, icon, description = [] }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Ensure description is an array and not undefined
  const descriptionArray = Array.isArray(description) ? description : [];
  
  // Show only first paragraph when not expanded
  const displayedDescription = isExpanded || descriptionArray.length <= 1
    ? descriptionArray 
    : [descriptionArray[0]];

  return (
    <div className="group h-full bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transition-all duration-300 flex flex-col relative">
      {/* Decorative elements */}
      <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-green-400 to-green-600"></div>
      <div className="absolute top-0 right-0 w-12 h-12 bg-green-500 transform rotate-45 translate-x-6 -translate-y-6 opacity-20"></div>
      
      <div className="relative overflow-hidden h-56">
        <img
          src={image || "https://via.placeholder.com/400x300"}
          alt={title || "Speciality"}
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-700"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent opacity-60"></div>
        
        {/* Title overlay on image */}
        <div className="absolute bottom-0 left-0 w-full p-4">
          <h2 className="text-2xl font-bold text-white drop-shadow-lg flex items-center">
            <span className="bg-green-600 p-2 rounded-lg mr-3 shadow-lg">
              {icon || (
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              )}
            </span>
            {title || "Speciality Title"}
          </h2>
        </div>
      </div>
      
      <div className="p-6 flex-grow flex flex-col">
        <div className="prose prose-sm text-gray-700 flex-grow">
          {displayedDescription.length > 0 ? (
            displayedDescription.map((paragraph, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: paragraph || "" }} className="mb-4" />
            ))
          ) : (
            <p>No description available.</p>
          )}
        </div>
        
        {descriptionArray.length > 1 && (
          <button 
            onClick={() => setIsExpanded(!isExpanded)}
            className="mt-4 text-green-600 font-medium hover:text-green-800 transition-colors flex items-center self-start"
          >
            {isExpanded ? (
              <>
                Read less
                <svg 
                  className="w-4 h-4 ml-1" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                </svg>
              </>
            ) : (
              <>
                Read more
                <svg 
                  className="w-4 h-4 ml-1" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default SpecialityCard;
