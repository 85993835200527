import React from "react";
import Img1 from "../images/image1.JPG";
import Img2 from "../images/image2.JPG";
import Img3 from "../images/image3.JPG";
import Img4 from "../images/image4.JPG";
import Img5 from "../images/image5.JPG";
import Img6 from "../images/image6.JPG";
import Img7 from "../images/image7.JPG";
import Img8 from "../images/image8.JPG";
import Img9 from "../images/image9.JPG";
import Img10 from "../images/image10.JPG";
import Img11 from "../images/image11.JPG";
import Img12 from "../images/image12.JPG";

const GalleryImages = () => {
  const items = [
    {
      id: "1",
      src: Img1,
    },
    {
      id: "2",
      src: Img2,
    },
    {
      id: "3",
      src: Img3,
    },
    {
      id: "4",
      src: Img4,
    },
    {
      id: "5",
      src: Img5,
    },
    {
      id: "6",
      src: Img6,
    },
    {
      id: "7",
      src: Img7,
    },
    {
      id: "8",
      src: Img8,
    },
    {
      id: "9",
      src: Img9,
    },
    {
      id: "10",
      src: Img10,
    },
    {
      id: "11",
      src: Img11,
    },
    {
      id: "12",
      src: Img12,
    },
  ];
  return (
    <div>
      <div className="bg-orange-50">
        <div className="p-4 mx-auto flex flex-col justify-center">
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 sm:px-5">
            {items.map(({ id, src }) => (
              <div
                key={id}
                className="shadow-md shadow-gray-600 rounded-lg overflow-hidden h-72"
              >
                <img
                  src={src}
                  alt=""
                  className="rounded-md hover:scale-105 object-cover h-full w-full"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryImages;
