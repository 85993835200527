import React, { useState, useEffect } from "react";
import ColoredHashtags from "./Hastags";
import EventItems from "./EventItems";

const Event = () => {
  const [lightbox, setLightbox] = useState({
    isOpen: false,
    type: "",
    src: "",
    alt: "",
  });

  const openLightbox = (src, alt, type = "video") => {
    setLightbox({
      isOpen: true,
      type,
      src,
      alt,
    });
    document.body.style.overflow = "hidden";
  };

  const closeLightbox = () => {
    setLightbox({ ...lightbox, isOpen: false });
    document.body.style.overflow = "auto";
  };

  return (
    <div className="container mx-auto py-8 px-4">
      {EventItems.map((item, index) => (
        <div
          key={index}
          className="mb-4 overflow-hidden rounded-xl shadow-sm hover:shadow-md transition-all duration-300 border-l-4 border-amber-500 bg-white/80 backdrop-blur-sm"
        >
          <div className="flex flex-col md:flex-row">
            <div className="bg-gradient-to-br from-green-600 to-green-700 text-white md:w-16 flex-shrink-0 flex flex-col justify-center items-center p-2">
              <span className="text-lg font-bold">{item.event_date.day}</span>
              <span className="text-[10px] uppercase tracking-wider font-medium">
                {item.event_date.month}
              </span>
              <span className="text-xs">{item.event_date.year}</span>
            </div>

            <div className="flex-grow p-3">
              <h3 className="text-lg font-semibold mb-2 text-gray-800">
                {item.event_title}
              </h3>

              <div className="mb-3 text-sm text-gray-600">
                <ColoredHashtags text={item.event_description} />
              </div>

              <div className="space-y-2">
                {/* Media Content */}
                {item.facebook_video ? (
                  // Video Only Layout
                  <div className="flex justify-center">
                    <div className="rounded-lg overflow-hidden w-full max-w-2xl">
                      <div
                        className="relative group cursor-pointer"
                        onClick={() => openLightbox(item.facebook_video, `${item.event_title} video`, "video")}
                      >
                        <div className="relative" style={{ paddingBottom: "56.25%" }}>
                          <iframe
                            title={`${item.event_title} Facebook video`}
                            src={`https://www.facebook.com/plugins/video.php?href=${item.facebook_video}`}
                            className="absolute inset-0 w-full h-full rounded-lg transform group-hover:scale-[1.01] transition-transform duration-300"
                            allowFullScreen
                          />
                          <div className="absolute inset-0 bg-black/10 group-hover:bg-black/0 transition-colors duration-300" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  // Image Gallery
                  item.event_photos && item.event_photos.length > 0 && (
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-1.5">
                      {item.event_photos.map((src, photoIndex) => (
                        <div
                          key={photoIndex}
                          className="aspect-square rounded-lg overflow-hidden cursor-pointer relative group"
                          onClick={() =>
                            openLightbox(
                              typeof src === "string" ? src : src.default,
                              `${item.event_title} photo ${photoIndex + 1}`,
                              "image"
                            )
                          }
                        >
                          <img
                            src={typeof src === "string" ? src : src.default}
                            alt={`${item.event_title} ${photoIndex + 1}`}
                            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                          />
                          <div className="absolute inset-0 bg-black/0 group-hover:bg-black/20 transition-colors duration-300" />
                        </div>
                      ))}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Updated Lightbox */}
      {lightbox.isOpen && (
        <div className="fixed inset-0 bg-black/95 z-50 flex items-center justify-center p-4 backdrop-blur-sm">
          <div
            className="max-w-4xl max-h-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-4 right-4 bg-red-500 text-white hover:bg-opacity-50 rounded-full w-8 h-8 flex items-center justify-center transition-all z-10"
              onClick={closeLightbox}
            >
              ✕
            </button>

            {lightbox.type === "image" ? (
              <img
                src={lightbox.src}
                alt={lightbox.alt}
                className="max-h-[90vh] max-w-full object-contain rounded-lg shadow-2xl"
              />
            ) : (
              <div className="w-full max-w-4xl max-h-[90vh]">
                <iframe
                  title={lightbox.alt || "Facebook video lightbox"}
                  src={`https://www.facebook.com/plugins/video.php?href=${lightbox.src}`}
                  width="734"
                  height="412"
                  className="rounded-lg shadow-2xl"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Event;
