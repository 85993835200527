import React from 'react';

function ColoredHashtags({ text }) {
  const coloredText = text.split(' ').map((word, index) => {
    if (word.startsWith('#')) {
      return (
        <span 
          key={index} 
          className="text-blue-600 font-medium hover:underline cursor-pointer"
        >
          {word}{' '}
        </span>
      );
    } else {
      return <span key={index}>{word} </span>;
    }
  });

  return <p className="leading-relaxed">{coloredText}</p>;
}

export default ColoredHashtags;
