import React from "react";
import Sankha from "../images/sankha.JPG";
import Karate from "../images/karate.JPG";
import Violin from "../images/violin.JPG";
import Pe from "../images/pe.JPG";

const BeyondAcademics = () => {
  // Features data for easier management
  const features = [
    {
      id: 1,
      title: "Extra Curricular Activities (ECA) for Overall Development",
      description: "Panini School recognizes the importance of going beyond textbooks for the comprehensive development of each student. Our Extra-Curricular Activities (ECA) programs are meticulously designed to ensure the students evolve into well-rounded individuals, fostering qualities that go beyond traditional academic learning."
    },
    {
      id: 2,
      title: "Regular Mass Speaking",
      description: "As a crucial aspect of our holistic approach, students engage in regular public speaking sessions, addressing a mass audience daily. This initiative not only aims to develop their confidence but also serves as a proactive measure against xenophobia. By encouraging students to express themselves in front of others, we instill vital communication and presentation skills that are essential for their personal and professional growth."
    },
    {
      id: 3,
      title: "Rehearse (Voice Therapy)",
      description: "Voice therapy at Panini School goes beyond making a child a proficient vocalist; it focuses on enhancing clarity in voice during conversations and speeches. This initiative acknowledges the importance of effective communication skills in various contexts, preparing students to articulate their thoughts with confidence and precision."
    },
    {
      id: 4,
      title: "Sports-Friendly Environment",
      description: "Panini school takes pride in being a sports-friendly institution, providing a wide range of facilities for students to engage in games and sports regularly. Our programs are strategically designed to take place in the evenings after regular school activities, ensuring that every student actively participates in physical activities. This commitment not only promotes physical well-being but also fosters teamwork, discipline, and a spirit of healthy competition."
    }
  ];

  // Image gallery data
  const galleryImages = [
    { src: Sankha, alt: "Student playing sankha", borderColor: "border-orange-300" },
    { src: Karate, alt: "Students practising karate", borderColor: "border-green-600" },
    { src: Violin, alt: "Students playing the violin", borderColor: "border-green-600" },
    { src: Pe, alt: "Students doing physical exercise", borderColor: "border-orange-400" }
  ];

  return (
    <div className="bg-gradient-to-b from-orange-50 to-white py-16">
      <div className="container mx-auto px-6 md:px-12">
        {/* Header Section */}
        <div className="max-w-4xl mx-auto text-center mb-16">
          <h2 className="font-heading font-bold text-4xl md:text-5xl text-green-950 mb-8 relative inline-block">
            WHY PANINI?
            <span className="absolute top-14 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-orange-400 rounded-full "></span>
          </h2>
          
          <p className="text-lg md:text-xl text-gray-700 leading-relaxed">
            Join us for a holistic educational experience that combines the
            understanding of Eastern culture, norms, and values with the discipline
            of a traditional academic environment. Here, students not only delve
            into the rich tapestry of Eastern culture but also learn the importance
            of respect, harmony, and community values deeply rooted in this
            heritage. Furthermore, they are exposed to rigorous academic programs
            infused with Western technological advancements, ensuring they are
            equipped with the necessary skills to thrive in today's interconnected
            world. This unique blend of Eastern wisdom and Western academic
            excellence fosters well-rounded individuals capable of navigating
            diverse cultural landscapes while excelling in their academic pursuits.
          </p>
        </div>

        {/* Gallery Section */}
        <div className="mb-20">
          <div className="grid md:grid-cols-2 gap-6 md:gap-8">
            {galleryImages.map((image, index) => (
              <div 
                key={index} 
                className="overflow-hidden rounded-2xl shadow-lg transform transition-all duration-300 hover:shadow-xl hover:-translate-y-2"
                style={{ 
                  opacity: 0,
                  animation: 'fadeIn 0.8s ease forwards',
                  animationDelay: `${index * 0.2}s`
                }}
              >
                <div className={`p-2 ${image.borderColor} rounded-2xl overflow-hidden`}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="w-full h-64 md:h-80 object-cover rounded-xl transform transition-transform duration-500 hover:scale-105"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Features Section */}
        <div className="grid md:grid-cols-2 gap-8 md:gap-12">
          {features.map((feature, index) => (
            <div 
              key={feature.id} 
              className="bg-white p-8 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300"
              style={{ 
                opacity: 0,
                animation: 'slideUp 0.6s ease forwards',
                animationDelay: `${index * 0.15}s`
              }}
            >
              <div className="flex items-start mb-4">
                <div className="flex-shrink-0 bg-orange-100 rounded-full p-3 mr-4">
                  <span className="text-orange-500 font-bold text-xl">{feature.id}</span>
                </div>
                <h3 className="text-xl font-semibold text-orange-500">{feature.title}</h3>
              </div>
              <p className="text-gray-700 leading-relaxed ml-16">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Animation styles */}
      <style jsx global>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        
        @keyframes slideUp {
          from { 
            opacity: 0;
            transform: translateY(20px);
          }
          to { 
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default BeyondAcademics;
