import React, { useState } from "react";
import ErrorMessage from "./ErrorMessage";

const ContactUs = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value, pattern } = e.target;
    let error = "";

    if (name === "message" && value.trim().split(/\s+/).length < 5) {
      error = "Message must be at least 5 words long.";
    } else if (pattern && !new RegExp(pattern).test(value)) {
      error = e.target.validationMessage;
    }

    setValues({
      ...values,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent the default form submission
    var name = document.getElementById("name").value;
    var email = document.getElementById("email").value;
    var message = document.getElementById("textarea").value;
    var subject = "Query from " + name;

    // Check if the browser supports opening mailto links
    if ("mailto:" in navigator) {
      var mailtoLink =
        "mailto:panininetwork@gmail.com" +
        "?subject=" +
        encodeURIComponent(subject) +
        "&body=" +
        encodeURIComponent(message + "\n\nSent from: " + email);
      window.open(mailtoLink, "_blank");
    } else {
      var gmailLink =
        "https://mail.google.com/mail/?view=cm&fs=1&to=panininetwork@gmail.com" +
        "&su=" +
        encodeURIComponent(subject) +
        "&body=" +
        encodeURIComponent(message + "\n\nSent from: " + email);
      window.open(gmailLink, "_blank");
    }
  };

  return (
    <div>
      <section className="bg-orange-50" id="contact">
        <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
          <div className="mb-4">
            <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
              <h2 className="font-heading mb-4 font-bold tracking-tight text-green-950 text-3xl sm:text-5xl">
                Get in Touch
              </h2>
            </div>
          </div>
          <div className="flex items-stretch justify-center">
            <div className="grid md:grid-cols-2">
              <div className="h-full pr-6">
                <p className="mt-3 mb-12 text-lg text-green-900">
                  Feel free to reach out to us! We're here to help and provide
                  you with any information you need regarding Panini School.
                  Whether it's through phone, email, or in person, we are ready
                  to assist you.
                </p>
                <ul className="mb-6 md:mb-0">
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-green-900">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="mb-2 text-lg font-medium leading-6 text-green-900">
                        Our Address
                      </h3>
                      <p className="text-gray-800 font-semibold">
                      Kageshwori Manohara-9, Kathmandu, Nepal
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-green-900 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                        <path d="M15 7a2 2 0 0 1 2 2"></path>
                        <path d="M15 3a6 6 0 0 1 6 6"></path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="mb-2 text-lg font-medium leading-6 text-green-900">
                        Contact
                      </h3>
                      <p className="text-gray-800 font-semibold">
                        Tel/Fax: +01 - 4993290
                      </p>
                      <p className="text-gray-800 font-semibold">
                        Mail: panininetwork@gmail.com
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-green-900 text-gray-50">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                        <path d="M12 7v5l3 3"></path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="mb-2 text-lg font-medium leading-6 text-green-900">
                        Working hours
                      </h3>
                      <p className="text-gray-800 font-semibold">
                        Sunday - Friday: 07:00 A.M. - 06:00 P.M.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="card h-fit max-w-6xl p-5 md:p-12" id="form">
                <h2 className="mb-4 text-2xl font-bold text-orange-400">
                  Send Us A Query
                </h2>
                <form id="contactForm">
                  <div className="mb-6">
                    <div className="mx-0 mb-1 sm:mb-4">
                      <input
                        type="text"
                        value={values.name}
                        id="name"
                        autoComplete="given-name"
                        placeholder="Your full name"
                        className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md sm:mb-0 text-green-900"
                        name="name"
                        onChange={handleChange}
                        pattern="^[a-zA-Z]+ [a-zA-Z]+$"
                        required
                      />
                      {errors.name && <ErrorMessage message={errors.name} />}
                    </div>
                    <div className="mx-0 mb-1 sm:mb-4">
                      <input
                        type="email"
                        value={values.email}
                        id="email"
                        autoComplete="email"
                        placeholder="Your email address"
                        className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md text-green-900 sm:mb-0"
                        name="email"
                        onChange={handleChange}
                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                        required
                      />
                      {errors.email && <ErrorMessage message={errors.email} />}
                    </div>
                  </div>
                  <div className="mx-0 mb-1 sm:mb-4">
                    <textarea
                      id="textarea"
                      value={values.message}
                      name="message"
                      cols="30"
                      rows="5"
                      placeholder="Write your message..."
                      className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md text-green-900 sm:mb-0"
                      onChange={handleChange}
                    />
                    {errors.message && (
                      <ErrorMessage message={errors.message} />
                    )}
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      onClick={sendEmail}
                      className="w-full bg-blue-800 text-white px-6 py-3 font-xl rounded-md sm:mb-0"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.6750335809716!2d85.36432797492267!3d27.696437025973594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1a306be3a127%3A0x9bf7eb5afb3881b0!2sPanini%20School!5e0!3m2!1sen!2snp!4v1709099126703!5m2!1sen!2snp"
            width="100%"
            height="450"
            style={{ border: "0" }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
