import React, { useState } from "react";
import { AboutMenuItems } from "./MenuItems";
import { Link } from "react-router-dom";
import "./Dropdown.css";

const AboutDropdown = ({ setClick }) => {
  const [click, setClick1] = useState(false);

  const handleClick = () => setClick1(!click);

  return (
    <div className="absolute m-0 p-0 md:mt-48 md:pt-32">
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {AboutMenuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link
                to={item.path}
                className={item.cName}
                onClick={() => {
                  setClick1(false);
                  setClick(false);
                }}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AboutDropdown;
