import React from 'react'
import GalleryImages from '../GalleryImages'

const Gallery = () => {
  return (
    <div>
      <GalleryImages />
    </div>
  )
}

export default Gallery
