import React from "react";

// Import all images
import LibraryImg from "../images/library.jpg";
import MedicalImg from "../images/vaccination1.jpg";
import YogaImg from "../images/Yoga-at-Panini.jpg";
import CafeteriaImg from "../images/cafeteria.jpg";
import TransportImg from "../images/transport.jpg";

// Icons as components for better reusability
const LibraryIcon = () => (
  <svg className="w-6 h-6 text-white flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
  </svg>
);

const MedicalIcon = () => (
  <svg className="w-6 h-6 text-white flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
  </svg>
);

const MemoryIcon = () => (
  <svg className="w-6 h-6 text-white flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
  </svg>
);

const CafeteriaIcon = () => (
  <svg className="w-6 h-6 text-white flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
  </svg>
);

const TransportIcon = () => (
  <svg className="w-6 h-6 text-white flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
  </svg>
);

const specialitiesData = [
  {
    id: "library",
    title: "Library",
    image: LibraryImg,
    icon: <LibraryIcon />,
    description: [
      "Library of <span class=\"text-green-600 font-medium\">Panini School</span> is a corner-store for healthy community to benefit the learners/explorers. Various books, reference materials are introduced to the maximum for the readers in a full-fledged digital library.",
      
      "The students can be equipped with unique and valuable resources to exhibit their learning and reading skills and showcase the positive impact on the academics. To enhance effective teaching and learning, <span class=\"text-green-600 font-medium\">Panini School</span> strongly facilitates the students along with their involvement in E-Learning, which is a theory that describes the cognitive science principles of effective multimedia learning using electronic educational technology.",
      
      "We believe that selection of appropriate multimedia modalities enhance their learning. We also believe that children learn best when they understand how to learn! We have designed twenty minutes reading and learning session for students everyday in an alternative period throughout the week and has been named 'My Dear Time'."
    ]
  },
  {
    id: "medical",
    title: "Medical Services",
    image: MedicalImg,
    icon: <MedicalIcon />,
    description: [
      "<span class=\"text-green-600 font-medium\">Panini School</span> vows to implement health care plans for students and staffs with special health care needs, healthy school environment and healthy education. The objectives of Panini school health are to improve student's achievement with sound health skills.",
      
      "We provide health counseling, emergency care for injury or illness to ensure that all students get appropriate vigilance and for these consequences we have a separate nursing department with trained nurse for supervision and hospitality. We also provide health insurance facilities which is a mandatory to every member of school."
    ]
  },
  {
    id: "memory",
    title: "Memory Power",
    image: YogaImg,
    icon: <MemoryIcon />,
    description: [
      "At <span class=\"text-green-600 font-medium\">Panini School</span>, we believe that cognitive development is a crucial part of holistic education. Our Memory Power program helps students develop techniques to enhance retention, recall, and critical thinking abilities.",
      
      "Through specialized exercises, games, and guided practice, students learn memory-enhancing strategies that benefit them not only in academics but also in everyday life. The program is designed to be fun, engaging, and adaptive to different learning styles.",
      
      "Regular memory training sessions are integrated into the curriculum, creating an environment where students naturally develop stronger memory skills over time."
    ]
  },
  {
    id: "cafeteria",
    title: "Cafeteria",
    image: CafeteriaImg,
    icon: <CafeteriaIcon />,
    description: [
      "When children don't eat a healthy lunch, it's harder for them to muster the energy for school activities. So it's vital your child eats healthy meals as it ensures health and well-being, improves academic performance, prevents obesity, helps them stay focused, pay attention in class and learn the information presented to them in their class and after-school activities.",
      
      "Therefore it is important to ensure well-balanced meals so that your child receives good nutrition. Since Panini runs semi-residential and full boarding system in its holding company, it has left no stone unturned and never compromises to provide healthy and hygienic food to semi-residential students and staff, and 5 meals to boarding school students a day including seasonal fruits and milk at an affordable cost."
    ]
  },
  {
    id: "transport",
    title: "Transport",
    image: TransportImg,
    icon: <TransportIcon />,
    description: [
      "Transporting a large group of kids is no easy task, especially when we have the responsibility of every child's safety and comfort. Each of our buses has an experienced driver, a conductor and a governor on board for pre-school children.",
      
      "Children are helped into and off the bus at all times. Attendance is taken on the bus to ensure that everyone on the route has found their way to the right bus. The school aims to design a highly standard and comfortable transport system to ensure that each young one has a wonderful time coming to school and going home.",
      
      "School appreciates children to be accompanied by their parents/guardians even when they find school at their shortest distance. However, parents residing further distance are encouraged to use the school transport system for security and safety purposes."
    ]
  }
];

export default specialitiesData;
