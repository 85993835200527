const EventItems = [
  {
    event_title: "Yog Class",
    event_description:
      "Grade 5 engaged in Yog Class at Panini #panini #yoga",
    event_date: { year: "2025", month: "Mar", day: "23" },
    facebook_video:
      "https://www.facebook.com/paninischoolnepal/videos/1553494831995339",
  },
  {
    event_title: "Organic Farming: A Hands-On Learning Experience!",
    event_description:
      "Grade 6 students of Panini School showcase their long-term organic farming project, sharing their journey of sustainability, responsibility, and growth with their parents.",
    event_date: { year: "2025", month: "Mar", day: "19" },
    facebook_video:
      "https://www.facebook.com/paninischoolnepal/videos/1312429419989134",
  },
  {
    event_title: "Holi Celebration!",
    event_description: "Pictures from our Holi celebration 2081",
    event_date: { year: "2025", month: "Mar", day: "12" },
    event_photos: [
      "https://scontent.fktm3-1.fna.fbcdn.net/v/t39.30808-6/482059623_939437595029983_8514851537208741487_n.jpg?stp=cp6_dst-jpg_tt6&_nc_cat=111&ccb=1-7&_nc_sid=833d8c&_nc_ohc=w3NKIHrZnPAQ7kNvgG4Q079&_nc_oc=Adnuwz793N4ikwVTLs2t68ZYzQNv7PHNrPJWE3-1X_aKowwUtl3EfhXr2qpB3pC1swo-GfDkmxAlqBfaqSD7_r8c&_nc_zt=23&_nc_ht=scontent.fktm3-1.fna&_nc_gid=obZZGIVuNhj46SM1C6EMhw&oh=00_AYFxPaxzh7QWCxR5YOcyc96WsXk8Bk4FZkNYXi-k5NYKBw&oe=67E94D61",
      "https://scontent.fktm3-1.fna.fbcdn.net/v/t39.30808-6/484017017_939437741696635_397446345001160679_n.jpg?stp=cp6_dst-jpg_tt6&_nc_cat=105&ccb=1-7&_nc_sid=833d8c&_nc_ohc=VlVTDsrs1tcQ7kNvgGbiHhX&_nc_oc=AdlaXhEaK42QhPay_mIkkW2mQ7V8-hbju5VNjP0DrTOqKK08L4Bqzxk3uiDZiEuyy5OkKvauh0CDrWSbiiXswnKa&_nc_zt=23&_nc_ht=scontent.fktm3-1.fna&_nc_gid=pFTau82StEH80QQld5eiOw&oh=00_AYE0jEUa1xGynTnLBWwbiPouBK7gncoQx0E35gWU0kkFjw&oe=67E952A2",
      "https://scontent.fktm3-1.fna.fbcdn.net/v/t39.30808-6/483953963_939442435029499_4122262794740326259_n.jpg?stp=cp6_dst-jpg_tt6&_nc_cat=104&ccb=1-7&_nc_sid=833d8c&_nc_ohc=AoBELGewKUYQ7kNvgHZUfXQ&_nc_oc=AdkurhNd9fzcFfDCo_LoJKL0I28FNMPV5ltDbZZ4mdkDRBXAAeRdSl_3-4jYopGihPfejg-nzYcJUePl1Zdxi_Jf&_nc_zt=23&_nc_ht=scontent.fktm3-1.fna&_nc_gid=r14llzEMNRElEx8_yV-vJQ&oh=00_AYFYk4W3knVGYqAPVLBMgw8il6QYF_dPU89YZ2VHB-5O7A&oe=67E96FE2",
      "https://scontent.fktm3-1.fna.fbcdn.net/v/t39.30808-6/484032723_939443361696073_7453400344860963391_n.jpg?stp=cp6_dst-jpg_tt6&_nc_cat=104&ccb=1-7&_nc_sid=833d8c&_nc_ohc=q3KkBeoqgIIQ7kNvgHA6ASe&_nc_oc=AdljkKTGttfF4YwgGlM6X6fQgl9lsSIrIA7A_9TOmkkKHHYoX6KgIZt9Y6DS2fuX5rTn-zo71T9f7P2lmf1Y7wjF&_nc_zt=23&_nc_ht=scontent.fktm3-1.fna&_nc_gid=y7--kbNikxjrvKo5BVAnhw&oh=00_AYFhuecwhur82Y_ZuGOhOy6XUuClt2RIe7HMl7dB0I64Jw&oe=67E978F0",
    ],
  },
  {
    event_title: "LTP Presentation",
    event_description:
      "Honoring the Past, Embracing the Future: Grade 4 students and their dedicated teachers team beautifully showcased their learning in the LTP presentation on Indigenous Culture and Technology. Heartfelt thanks to our supportive parents, passionate educators, and everyone who contributed to making this event a meaningful success!",
    event_date: { year: "2025", month: "Mar", day: "12" },
    facebook_video:
      "https://www.facebook.com/paninischoolnepal/videos/2034464367061555",
  },

  {
    event_title: "Basketball Tournament",
    event_description:
      "Pictures from 2nd U-13 Inter-School, Basketball Tournament!!",
    event_date: { year: "2025", month: "Mar", day: "11" },
    event_photos: [
      require("../images/events/basketball1.jpg"),
      require("../images/events/basketball2.jpg"),
      require("../images/events/basketball3.jpg"),
      "https://scontent.fktm3-1.fna.fbcdn.net/v/t39.30808-6/484018476_938612065112536_2911203864996429948_n.jpg?stp=cp6_dst-jpg_tt6&_nc_cat=104&ccb=1-7&_nc_sid=833d8c&_nc_ohc=Kf6IQSr7zZwQ7kNvgGvHKlO&_nc_oc=AdkVbaaax7rakMxDrlDVcCQPiOaYHT5oFbr9c1e9cyusF9ghS3NxUUleDB0LeJaSIJLtWvT-RYjKUHflAXIcC__x&_nc_zt=23&_nc_ht=scontent.fktm3-1.fna&_nc_gid=J0CIGVYLufzWZHC6RqE_uA&oh=00_AYEt1ySd0b2t8YjteNlNneK5SplhHUogG_h_Cvol79mwMQ&oe=67E93D59",
    ],
  },
  {
    event_title: "हर हर महादेव !",
    event_description:
      "Transcendental Bhakti through Mahadev Stotram. Our students are adapt in the Sanskrit slokas originated in time immemorial. Listen to the Out of the World performance by Grade Eight, Nine and Ten!",
    event_date: { year: "2025", month: "Feb", day: "22" },
    facebook_video:
      "https://www.facebook.com/paninischoolnepal/videos/1533893983952346",
  },
  {
    event_title: "Saraswoti Pooja",
    event_description: "Saraswoti Pooja 2081 @ Panini",
    event_date: { year: "2025", month: "Feb", day: "03" },
    event_photos: [
      require("../images/events/sarasworipooja2081_1.jpg"),
      require("../images/events/sarasworipooja2081_2.jpg"),
      require("../images/events/sarasworipooja2081_3.jpg"),
      require("../images/events/sarasworipooja2081_4.jpg"),
    ],
  },
  {
    event_title: "Radha Krishna Bhajan",
    event_description:
      "Students' soulful rendition of a Radha Krishna bhajan, filling the air with devotion and harmony!",
    event_date: { year: "2025", month: "Jan", day: "30" },
    facebook_video:
      "https://www.facebook.com/paninischoolnepal/videos/617871944078987",
  },
  {
    event_title: "Japanese Scouts Visit Panini School",
    event_description:
      "A Heartwarming Cultural Exchange! Grateful to ALT. Naresh Awale for making this collaboration possible! #ScoutsSharing #CulturalExchange #GlobalFriendship",
    event_date: { year: "2025", month: "Jan", day: "06" },
    facebook_video:
      "https://www.facebook.com/paninischoolnepal/videos/898162465860430",
  },
  {
    event_title: "Vaccination Program",
    event_description:
      "Vaccination drive against Dadura/Rubela facilitated by Gothatar Heath Post .We express gratitude to all participants of the campaign. #PaniniSchool #PaniniScouts #ProgressiveLearningCentre #paninimontessori #paninians #innovative #education #learning #EducationForAll #children",
    event_date: { year: "2024", month: "Mar", day: "06" },
    event_photos: [
      require("../images/events/vaccination1.jpg"),
      require("../images/events/vaccination2.jpg"),
      require("../images/events/vaccination3.jpg"),
      require("../images/events/vaccination4.jpg"),
    ],
  },
  {
    event_title: "Chitlang Diary",
    event_description:
      "Chitlang Diary- Our  Grade 3 and 4 students carved a wonderful Night-Out moments.Thank you Gautam Holidays Uttam Gautam sir for wonderful management. #PaniniSchool #PaniniMontessori #PaniniScouts #ProgressiveLearningCentre #education #Children #learning #success #paninians #innovative #educationforall #schoolactivity #education #learning #success",
    event_date: { year: "2024", month: "Mar", day: "06" },
    event_photos: [
      require("../images/events/chitlang1.jpg"),
      require("../images/events/chitlang2.jpg"),
      require("../images/events/chitlang3.jpg"),
      require("../images/events/chitlang4.jpg"),
    ],
  },
  {
    event_title: "UKG Graduation Ceremony",
    event_description:
      "Some snaps taken during U.K.G Graduation Ceremony 2080!! #PaniniSchool #PaniniMontessori #PaniniScouts #ProgressiveLearningCentre #education #Children #learning #success #paninians #innovative #educationforall #schoolactivity #education #learning #success",
    event_date: { year: "2024", month: "Feb", day: "22" },
    event_photos: [
      require("../images/events/graduation1.jpg"),
      require("../images/events/graduation2.jpg"),
      require("../images/events/graduation3.jpg"),
      require("../images/events/graduation4.jpg"),
    ],
  },
  {
    event_title: "7th Foundation Day",
    event_description:
      "7th Foundation Day, Still shots! #PaniniSchool #PaniniMontessori #PaniniScouts #ProgressiveLearningCentre #education #Children #learning #success #paninians #innovative #educationforall #schoolactivity #education #learning #success #foundationday",
    event_date: { year: "2024", month: "Feb", day: "21" },
    event_photos: [
      require("../images/events/foundationday1.jpg"),
      require("../images/events/foundationday2.jpg"),
      require("../images/events/foundationday3.jpg"),
      require("../images/events/foundationday4.jpg"),
    ],
  },
  {
    event_title: "Saraswoti Pooja 2080",
    event_description: "Saraswoti Pooja 2080",
    event_date: { year: "2024", month: "Feb", day: "14" },
    event_photos: [
      require("../images/events/saraswotipuja1.jpg"),
      require("../images/events/saraswotipuja2.jpg"),
      require("../images/events/saraswotipuja3.jpg"),
      require("../images/events/saraswotipuja4.jpg"),
    ],
  },
  {
    event_title: "Handwritring Competition",
    event_description:
      "Congratulations to our students on their selection to the provincial-level handwriting competition! Best wishes as they gear up for the upcoming round.",
    event_date: { year: "2023", month: "Dec", day: "30" },
    event_photos: [
      require("../images/events/handwriting1.jpg"),
      require("../images/events/handwriting2.jpg"),
      require("../images/events/handwriting3.jpg"),
      require("../images/events/handwriting4.jpg"),
    ],
  },
  {
    event_title: "Taekwondo Belt Upgrades",
    event_description:
      "A martial artist has to take responsibility for himself and accept the consequences of his own doing– Bruce Lee Taekwondo Belt Upgrades at Panini: Our students are excelling in self defence. #taekwondo",
    event_date: { year: "2023", month: "Dec", day: "25" },
    event_photos: [
      require("../images/events/taekwondo1.jpg"),
      require("../images/events/taekwondo2.jpg"),
      require("../images/events/taekwondo3.jpg"),
      require("../images/events/taekwondo4.jpg"),
    ],
  },
  {
    event_title: "Montessori Dry Picnic",
    event_description:
      "Montessori magic on the hilltop! Dry picnic delights at Pilot Baba Hill Temple with our tiny explorers. #GradeLKGandUKG #PaniniSchool #ProgressiveLearningCentre #paninimontessori #PaniniScouts #paninians #innovative #education #learning #EducationForAll #children",
    event_date: { year: "2023", month: "Dec", day: "19" },
    event_photos: [
      require("../images/events/picnic1.jpg"),
      require("../images/events/picnic2.jpg"),
      require("../images/events/picnic3.jpg"),
      require("../images/events/picnic4.jpg"),
    ],
  },
  {
    event_title: "Five Days of Tour",
    event_description: "Five Days of Tour ! Everlasting Days of Memory !!",
    event_date: { year: "2023", month: "Dec", day: "09" },
    event_photos: [
      require("../images/events/tour1.jpg"),
      require("../images/events/tour2.jpg"),
      require("../images/events/tour3.jpg"),
      require("../images/events/tour4.jpg"),
    ],
  },
];

export default EventItems;
