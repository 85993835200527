// import React from "react";
// import FeeTable from "../FeeTable";

// const Fees = () => {
//   const pg = [
//     { particulars: "Admission Fee", fees: "9500" },
//     { particulars: "Registration Fee", fees: "9500" },
//     { particulars: "Annual Fee", fees: "9500" },
//     { particulars: "Maintenance", fees: "8500" },
//     { particulars: "Monthly Fee", fees: "9500" },
//   ];

//   const lkg = [
//     { particulars: "Admission Fee", fees: "9500" },
//     { particulars: "Registration Fee", fees: "9500" },
//     { particulars: "Annual Fee", fees: "9500" },
//     { particulars: "Maintenance", fees: "8500" },
//     { particulars: "Monthly Fee", fees: "10500" },
//   ];

//   const ukg = [
//     { particulars: "Admission Fee", fees: "9500" },
//     { particulars: "Registration Fee", fees: "9500" },
//     { particulars: "Annual Fee", fees: "9500" },
//     { particulars: "Maintenance", fees: "8500" },
//     { particulars: "Monthly Fee", fees: "11500" },
//   ];

//   const one = [
//     { particulars: "Admission Fee", fees: "9500" },
//     { particulars: "Registration Fee", fees: "9500" },
//     { particulars: "Annual Fee", fees: "9500" },
//     { particulars: "Maintenance", fees: "9000" },
//     { particulars: "Monthly Fee", fees: "15500" },
//   ];

//   const two = [
//     { particulars: "Admission Fee", fees: "9500" },
//     { particulars: "Registration Fee", fees: "9500" },
//     { particulars: "Annual Fee", fees: "9500" },
//     { particulars: "Maintenance", fees: "9000" },
//     { particulars: "Monthly Fee", fees: "16000" },
//   ];

//   const three = [
//     { particulars: "Admission Fee", fees: "9500" },
//     { particulars: "Registration Fee", fees: "9500" },
//     { particulars: "Annual Fee", fees: "9500" },
//     { particulars: "Maintenance", fees: "9000" },
//     { particulars: "Monthly Fee", fees: "16500" },
//   ];

//   const four = [
//     { particulars: "Admission Fee", fees: "9500" },
//     { particulars: "Registration Fee", fees: "9500" },
//     { particulars: "Annual Fee", fees: "9500" },
//     { particulars: "Maintenance", fees: "9000" },
//     { particulars: "Monthly Fee", fees: "17000" },
//   ];

//   const five = [
//     { particulars: "Admission Fee", fees: "9500" },
//     { particulars: "Registration Fee", fees: "9500" },
//     { particulars: "Annual Fee", fees: "9500" },
//     { particulars: "Maintenance", fees: "9000" },
//     { particulars: "Monthly Fee", fees: "17500" },
//   ];

//   const six = [
//     { particulars: "Admission Fee", fees: "10000" },
//     { particulars: "Registration Fee", fees: "10000" },
//     { particulars: "Annual Fee", fees: "10000" },
//     { particulars: "Maintenance", fees: "10000" },
//     { particulars: "Monthly Fee", fees: "18000" },
//   ];

//   const seven = [
//     { particulars: "Admission Fee", fees: "10000" },
//     { particulars: "Registration Fee", fees: "10000" },
//     { particulars: "Annual Fee", fees: "10000" },
//     { particulars: "Maintenance", fees: "10000" },
//     { particulars: "Monthly Fee", fees: "18500" },
//   ];

//   const eight = [
//     { particulars: "Admission Fee", fees: "10000" },
//     { particulars: "Registration Fee", fees: "10000" },
//     { particulars: "Annual Fee", fees: "10000" },
//     { particulars: "Maintenance", fees: "10000" },
//     { particulars: "Monthly Fee", fees: "19000" },
//   ];

//   const nine = [
//     { particulars: "Admission Fee", fees: "10000" },
//     { particulars: "Registration Fee", fees: "10000" },
//     { particulars: "Annual Fee", fees: "10000" },
//     { particulars: "Maintenance", fees: "10000" },
//     { particulars: "Monthly Fee", fees: "19500" },
//   ];

//   const ten = [
//     { particulars: "Admission Fee", fees: "10000" },
//     { particulars: "Registration Fee", fees: "10000" },
//     { particulars: "Annual Fee", fees: "10000" },
//     { particulars: "Maintenance", fees: "10000" },
//     { particulars: "Monthly Fee", fees: "20000" },
//   ];

//   const eca = [
//     { particulars: "P.G. - UKG", fees: "3000" },
//     { particulars: "One - Five", fees: "7500" },
//     { particulars: "Six - Ten", fees: "7500" },
//   ];

//   const stationery = [
//     { particulars: "P.G. - UKG", fees: "2500" },
//     { particulars: "One - Three", fees: "3500" },
//     { particulars: "Four - Seven", fees: "4000" },
//     { particulars: "Eight - Ten", fees: "4500" },
//   ];

//   const transport = [{ particulars: "Yearly", fees: "30000, 36000, 42000" }];

//   const uniform = [{ particulars: "Tie, Belt, Diary & ID Card", fees: "2000" }];

//   return (
//     <div>
//       {/* <p className="text-4xl text-center my-20 font-extrabold"></p> */}
//       <h2 class="font-heading text-center my-20 font-bold tracking-tight text-green-950 text-3xl sm:text-5xl">
//       Fee Structure
//               </h2>
//       <div className="container mx-auto bg-orange-50 mb-8">
//       <div className="grid md:grid-cols-3 sm:grid-cols-1">
//         <FeeTable name="Stationery" fees={stationery} />
//         <FeeTable name="ECA" fees={eca} />
//         <FeeTable name="Transportation" fees={transport} />
//         <FeeTable name="Uniform" fees={uniform} />
//         <FeeTable name="P.G./Nursery" fees={pg} />
//         <FeeTable name="L.K.G." fees={lkg} />
//         <FeeTable name="U.K.G." fees={ukg} />
//         <FeeTable name="One" fees={one} />
//         <FeeTable name="Two" fees={two} />
//         <FeeTable name="Three" fees={three} />
//         <FeeTable name="Four" fees={four} />
//         <FeeTable name="Five" fees={five} />
//         <FeeTable name="Six" fees={six} />
//         <FeeTable name="Seven" fees={seven} />
//         <FeeTable name="Eight" fees={eight} />
//         <FeeTable name="Nine" fees={nine} />
//         <FeeTable name="Ten" fees={ten} />
//       </div>
//       </div>
//     </div>
//   );
// };

// export default Fees;

import React from "react";
import FeeTable from "../FeeTable";

const Fees = () => {
  // One-time Admission Fee section
  const admissionFees = [
    { particulars: "P.G. - U.K.G", fees: "50,000" },
    { particulars: "Grade 1 - 5", fees: "60,000" },
    { particulars: "Grade 6 - 10", fees: "70,000" },
  ];

  // Annual Fee section
  const annualFees = [
    { particulars: "L.K.G. - U.K.G", fees: "25,000" },
    { particulars: "Grade 1 - 5", fees: "30,000" },
    { particulars: "Grade 6 - 10", fees: "35,000" },
  ];

  // Monthly Fee by class
  const monthlyFees = [
    { class: "P.G", fees: "9,500" },
    { class: "Nursery", fees: "10,500" },
    { class: "L.K.G", fees: "11,500" },
    { class: "U.K.G", fees: "12,500" },
    { class: "One", fees: "17,000" },
    { class: "Two", fees: "17,500" },
    { class: "Three", fees: "18,000" },
    { class: "Four", fees: "18,500" },
    { class: "Five", fees: "19,000" },
    { class: "Six", fees: "19,500" },
    { class: "Seven", fees: "20,000" },
    { class: "Eight", fees: "20,500" },
    { class: "Nine", fees: "21,000" },
    { class: "Ten", fees: "21,500" },
  ];

  // ECA (Quarterly)
  const eca = [
    { particulars: "P.G - UKG", fees: "4,000" },
    { particulars: "One - Ten", fees: "9,000" },
  ];

  // Stationary (Quarterly)
  const stationery = [
    { particulars: "P.G - UKG", fees: "3,000" },
    { particulars: "One - Ten", fees: "4,500" },
  ];

  // Transportation (Quarterly) - exact format from photo
  const transport = [
    {
      particulars: "Transportation (Quarterly)",
      fees: "9,000, 10,500, 11,500",
    },
  ];

  // Uniforms and Accessories
  const uniform = [
    {
      particulars: "Tie, belt, diary, ID Card, Examination (1-10)",
      fees: "6,000",
    },
    { particulars: "Diary, ID Card, Examination (Montessori)", fees: "5,000" },
  ];

  return (
    <div className="bg-gradient-to-b from-white to-orange-50 py-10">
      <h2 className="font-heading text-center mb-10 font-bold tracking-tight text-green-950 text-3xl sm:text-5xl">
        Fee Structure
      </h2>
      <div className="container mx-auto px-4">
        {/* Main fee sections in a more evenly balanced layout */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-10">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="bg-green-800 text-white p-4 text-center font-bold text-xl">
              Admission Fee (One Time)
            </div>
            <FeeTable fees={admissionFees} customClass="admission" />
          </div>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="bg-green-800 text-white p-4 text-center font-bold text-xl">
              Annual Fee
            </div>
            <FeeTable fees={annualFees} customClass="annual" />
          </div>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="bg-green-800 text-white p-4 text-center font-bold text-xl">
              ECA (Quarterly)
            </div>
            <FeeTable fees={eca} customClass="eca" />
          </div>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="bg-green-800 text-white p-4 text-center font-bold text-xl">
              Stationary (Quarterly)
            </div>
            <FeeTable fees={stationery} customClass="stationary" />
          </div>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="bg-green-800 text-white p-4 text-center font-bold text-xl">
              Transportation
            </div>
            <table className="w-full text-left">
              <tbody>
                <tr className="bg-white hover:bg-green-50 transition-colors duration-150">
                  <td className="px-4 py-3 border-b border-gray-200">
                    Transportation (Quarterly)
                  </td>
                  <td className="px-4 py-3 border-b border-gray-200 text-right font-medium text-green-900">
                    RS. 9,000, 10,500, 11,500
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="bg-green-800 text-white p-4 text-center font-bold text-xl">
              Uniforms & Accessories
            </div>
            <FeeTable fees={uniform} customClass="uniform" />
          </div>
        </div>

        {/* Monthly fees section */}
        <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-6">
          <div className="bg-green-800 text-white p-4 text-center font-bold text-xl">
            Monthly Fees by Class
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            {monthlyFees.map((item, index) => (
              <div
                key={index}
                className="border-b border-r border-gray-200 p-4"
              >
                <h3 className="font-bold text-lg text-green-900">
                  Class: {item.class}
                </h3>
                <p className="text-gray-700">
                  Monthly Fee:{" "}
                  <span className="font-semibold">RS. {item.fees}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fees;
