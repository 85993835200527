import React from 'react'

const ErrorMessage = (props) => {
  return (
    <div>
        <p className='text-md text-red-500'>{props.message}</p>
    </div>
  )
}

export default ErrorMessage