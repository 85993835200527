import React from "react";
import Maharshi from "../images/maharshipanini.png"
import Img12 from "../images/image12.JPG"
import Img11 from "../images/image11.JPG"

const AboutUs = () => {
  return (
    <div>
      <section class="bg-gradient-to-br from-green-50 via-green-300 to-green-50 mt-20" id="about">

        {/* origin section */}
        <div className="w-full text-center bg-gradient-to-r from-orange-200 via-orange-400 to-orange-200 p-8">
        
          <h2 className="text-white font-extrabold text-4xl" style={{ fontFamily: '"Brush 455 W03 Rg"' }}>Origin</h2>

        </div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-7/12 bg-white text-green-950 py-16 px-16">
            <h3 className="text-green-950 font-bold leading-10">
              The eminent scholar Maharshi Panini is the inspiration behind the
              name of Panini School. Maharshi Panini made important
              contributions to linguistic studies and is regarded as the founder
              of Sanskrit grammar. In order to encourage pupils to delve deeper
              into language and communication, the school seeks to exemplify his
              spirit of knowledge and linguistic brilliance. The institution
              honours a visionary whose linguistic ideas continue the affect
              language education today by naming it after Maharshi Panini.
            </h3>
          </div>
          <div className="w-full md:w-5/12 bg-white text-white flex justify-center items-center">
          <div className="w-full h-full bg-gradient-to-l from-green-200 rounded-br-full flex justify-center"> <img
              src={Maharshi}
              width={300}
              alt="Maharshi Panini"
              className="rounded-xl object-cover"
            /></div>
           
          </div>
        </div>

        {/* welcome to panini section */}
        <div className="w-full text-center bg-gradient-to-r from-orange-200 via-orange-400 to-orange-200 p-8">
          {" "}
          <h2 className="text-white font-extrabold text-4xl" style={{ fontFamily: '"Brush 455 W03 Rg"' }}>
           
            Welcome To Panini
          </h2>
        </div>
        <div className="flex flex-wrap ">
          <div className="w-full md:w-7/12  bg-white text-white flex justify-center items-center">
            <img
              src={Img12}
              className="shadow-xl rounded-xl object-cover"
              style={{ width: 800, height: 450 }}
              alt="image12"
            />
          </div>
          <div className="w-full md:w-5/12 bg-white text-green-950 py-16 px-5">
            <h3 className="text-green-950 font-bold leading-10">
              Panini School stands as a testament to a decade of unwavering
              commitment to educational excellence. Founded by renowned
              educationists ten years ago, our institution has emerged as a
              pioneering force in shaping young minds for a dynamic future.
              Rooted in a philosophy that values innovation, inclusivity, and a
              holistic approach to education, Panini School is dedicated to
              providing an environment where students not only learn but thrive.
              Panini School is a distinguished educational institution fostering
              holistic development for over a decade in the heart of Kathmandu.
              With a focus on integrating Eastern and Western educational
              philosophies, we aim to nurture well-rounded individuals beyond
              academic excellence, emphasizing cultural awareness and moral
              ethics.
            </h3>
          </div>
        </div>

        {/* core values section */}
        <div className="w-full text-center bg-gradient-to-r from-orange-200 via-orange-400 to-orange-200 p-8">
          {" "}
          <h2 className="text-white font-extrabold text-4xl" style={{ fontFamily: '"Brush 455 W03 Rg"' }}>
            {" "}
            Core values At Panini
          </h2>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-7/12 bg-white text-green-950 py-16 px-5">
            <h2 className="font-extrabold text-green-800 text-xl">
              Education and Culture
            </h2>
            <h3 className="text-green-950 font-bold leading-10 mb-5">
              At the heart of Panini School's ethos are the core values of
              education and culture. We believe that academic achievement is
              inseparable from personal and cultural growth. Our commitment to
              fostering a strong sense of values, ethics, and cultural awareness
              ensures that our students graduate not just with knowledge but
              with a deep understanding of their place in the world. This
              holistic approach prepares them to contribute meaningfully to
              society with compassion and integrity.
            </h3>
            <h2 className="font-extrabold text-green-800 text-xl">
              Harmony of Educational Pedagogies
            </h2>
            <h3 className="text-green-950 font-bold leading-10">
              Panini School embraces a unique blend of Eastern and Western
              educational pedagogy. Recognizing the strength of both traditions,
              we integrate the best practices to offer a well rounded
              educational experience. This approach not only ensures academic
              excellence but also instills in our students a deep appreciation
              for diverse cultures and perspectives. We believe that a global
              outlook is essential in nurturing individuals who can thrive in an
              interconnected world.
            </h3>
          </div>
          <div className="w-full md:w-5/12  bg-white text-white flex justify-center items-center">
            <img
              src={Img11}
              className="shadow-xl rounded-xl object-cover"
              style={{ width: 500, height: 550 }}
              alt="image11"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
