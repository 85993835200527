import React from 'react'

const OurStrength = () => {

    const strengthItem = [
        'Panini Academic Curriculum',
        'Sanskrit',
        'Bhagavad Gita',
        'My D.E.A.R Time',
        'Art of Public Speaking',
        'Cricket',
        'Basketball',
        'Yoga',
        'Volleyball',
        'Music',
        'Martial Arts',
        'Arts',
        'Theatrical Arts/Drama',
        'Lawn Tennis',
        'Table Tennis',
        'Badminton',
        'Mechanical Workshop',
        'AI',
        'Abacus',
        'Swimming',
        'Scouts',
        'Futsal/ Football',
        'Drip Irrigation',
        'Tours and Travels'
    ]
  return (
    <div className='container mx-auto my-20 '>
        <p className='text-orange-400 text-4xl font-bold text-center pb-10' style={{ fontFamily: '"Brush 455 W03 Rg"' }}> Our Strengths</p>
    <div className="grid md:grid-cols-4 sm:grid-cols-2">
        {strengthItem.map((item, index) => (
            <div key={index} className="py-8 rounded-2xl m-8 bg-gradient-to-r from-green-800 to-green-600 text-white font-bold items-center hover:text-orange-200 hover:scale-110">
                <p className="text-center">{item}</p>
            </div>
        ))}
    </div>

    </div>
  )
}

export default OurStrength