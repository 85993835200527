import React from 'react'
import Event from '../Event'

const Events = () => {
  return (
    <div>
      <p className="text-5xl text-center text-green-900 font-bold my-10">
        Our Events
      </p>
        <Event />
    </div>
  )
}

export default Events;

